<template>
    <b-container fluid>
        <b-alert variant="danger" dismissible @dismissed="error = null; showError = false" :show="showError">{{ error
            }}
        </b-alert>
        <b-row class="mb-2">
            <b-col cols="12" md="4" sm="5" lg="4" xl="3" class="mt-1">
                <b-input-group>
                    <b-form-input v-model="filter.num" placeholder="Компания"/>
                    <b-input-group-append v-if="filter.num">
                        <b-button variant="outline-secondary" @click="filter.num = ''">
                            <b-icon-x scale="2" shift-v="-2"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col cols="12" md="4" sm="5" lg="4" xl="3" class="mt-1">
                <b-input-group>
                    <b-form-input v-model="filter.dlvId" placeholder="Логин"/>
                    <b-input-group-append v-if="filter.dlvId">
                        <b-button variant="outline-secondary" @click="filter.dlvId = ''">
                            <b-icon-x scale="2" shift-v="-2"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col class="text-right mt-1">
                <b-button variant="link" @click="$emit('pick', null)">Закрыть</b-button>
                <b-button v-if="selectMode === 'multi'" variant="primary" @click="$emit('pick', pickedList)">Выбрать
                </b-button>
            </b-col>
        </b-row>
        <b-table  ref="selectableTable" stacked="md" hover class="text-center" selectable :items="entities" :fields="tableFields"
                 :select-mode="selectMode" @row-selected="onRowSelected"/>
        <pagination @change="getEntities"/>
    </b-container>
</template>

<script>
    import Pagination from "../Pagination";

    export default {
        name: "ShipmentPicker",
        components: {Pagination},
        created() {
            this.getEntities();
        },
        props: {
            query: {
                type: String,
                required: false,
                default: () => null
            },
            selectMode: {
                type: String,
                required: false,
                default: () => 'multi'
            },
            externalFilter: {
                type: Array,
                required: false,
                default: () => []
            },

        },
        watch: {
            filter: {
                handler() {
                    this.getEntities();
                },
                deep: true
            },
            query(value) {
                this.filter.company = value;
                this.$emit('suggestions', this.entities);
            },
        },
        data() {
            return {
                entities: [],
                pickedList: [],
                error: null,
                showError: false,
                filter: {
                    num: '',
                    dlvId: ''
                },
                tableFields: [
                    {
                        key: 'num',
                        label: 'Номер'
                    }, {
                        key: 'shipmentType',
                        label: 'Тип'
                    }, {
                        key: 'dlvLogin',
                        label: 'Аккаунт'
                    }, {
                        key: 'status',
                        label: 'Статус',
                    }, {
                        key: 'courierCode',
                        label: 'Служба доставки',
                    }, {
                        key: 'courierNum',
                        label: 'Номер отслеживания КС',
                    }, {
                        key: 'dlvId',
                        label: 'DLV Id',
                    }, {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        methods: {
            onRowSelected(items) {
                this.pickedList = items;
                if (this.selectMode !== 'multi')
                    this.$emit('pick', this.pickedList[0]);
            },
            getEntities() {
                let filters = [
                    {
                        key: "num",
                        value: this.filter.num,
                        operation: "="
                    }, {
                        key: "dlvId",
                        value: this.filter.dlvId,
                        operation: "="
                    }
                ];
                if (this.externalFilter)
                    this.externalFilter.forEach(value => filters.push(value));
                this.$http.get(this.$root.apiUrl + '/api/shipments/',
                    {
                        params: {
                            page: 0,
                            size: 50,
                            filter: JSON.stringify(filters)
                        },
                        headers: this.$root.getHeaders()
                    })
                    .then(response => {
                        if (response.body.success) {
                            this.entities = response.body.list;
                        } else {
                            this.error = response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value);
                            this.showError = true;
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },

        }
    }
</script>

<style scoped>
</style>