<template>
    <div>
        <div class="input-group mb-3 w-100">
            <div :class="inputClass">
                <autocomplete :is-async="true" :disabled="disabled" :placeholder="placeholder" :items="items" :state="state" :init="init" @input="input" @select="select"/>
            </div>
            <div class="input-group-append" style="display: inline-block; height: 50px;" :style="{width: buttonWidth}" v-if="!disabled">
                <b-button variant="outline-secondary" class="append-button" v-if="search" @click="pickerClick">
                    <b-icon-search scale="1.5" shift-v="-2"/>
                </b-button>
                <b-button variant="outline-secondary" class="append-button" v-if="edit && init" @click="editClick">
                    <b-icon-pencil scale="2" shift-v="-2"/>
                </b-button>
                <b-button variant="outline-secondary" class="append-button" v-if="remove && init" @click="clearClick">
                    <b-icon-x scale="2" shift-v="-2"/>
                </b-button>
            </div>
        </div>
        <b-table v-if="values.length > 0" small :items="values" class="text-center" :fields="tableFields" :class="route !== 'null' ? 'table-hover' : ''" @row-clicked="toRoute">
            <template v-slot:cell(remove)="data">
                <b-icon-x-square scale="2" @click.stop="removeItem(data.index)" v-if="!disabled"/>
            </template>
        </b-table>
    </div>
</template>

<script>
    import Autocomplete from "./Autocomplete";
    import { BIconSearch, BIconXSquare, BIconPencil } from 'bootstrap-vue'

    export default {
        name: "EntityChooser",
        components: {Autocomplete, BIconSearch, BIconXSquare, BIconPencil},
        created() {
            this.calcWidth();
            if (this.tableFields)
                this.tableFields.push({key: 'remove', label: 'Исключить'});
        },
        props: {
            items: {
                type: Array,
                required: false,
                default: () => []
            },
            values: {
                type: Array,
                required: false,
                default: () => []
            },
            tableFields: {
                type: Array,
                required: false,
                default: () => []
            },
            init: {
                type: String,
                required: false,
                default: () => null
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            search: {
                type: Boolean,
                required: false,
                default: true
            },
            edit: {
                type: Boolean,
                required: false,
                default: false
            },
            remove: {
                type: Boolean,
                required: false,
                default: false
            },
            placeholder: {
                type: String,
                required: false,
                default: () => ''
            },
            route: {
                type: String,
                required: false,
                default: () => null
            },
            state: null
        },
        data() {
            return {
                inputClass: '',
                buttonWidth: ''
            }
        },
        watch: {
            init() {
                this.calcWidth();
            }
        },
        methods: {
            toRoute(item) {
              if (this.route !== null) {
                  this.$router.push({name: this.route, params: {id: item.id}});
              }
            },
            select(event) {
                this.$emit("select", event);
            },
            input(event) {
                this.$emit("input", event);
            },
            pickerClick(event) {
                this.$emit("picker-click", event);
            },
            editClick(event) {
                this.$emit("edit", event);
            },
            clearClick(event) {
                this.$emit("clear", event);
            },
            removeItem(event) {
                this.$emit("remove-item", event);
            },
            calcWidth() {
                let i = 0;
                if (this.search) i++;
                if (this.edit && this.init) i++;
                if (this.remove && this.init) i++;
                switch (i) {
                    case 1:
                        this.inputClass = "one-button";
                        break;
                    case 2:
                        this.inputClass = "two-buttons";
                        break;
                    case 3:
                        this.inputClass = "three-buttons";
                        break;
                    default:
                        this.inputClass = "no-buttons";
                }
                this.buttonWidth = ((this.search ? 50 : 0) + ((this.edit && this.init) ? 50 : 0) + ((this.remove && this.init) ? 50 : 0)) + 'px';
            }
        }
    }
</script>

<style scoped>
    .no-buttons {
        display: inline-block;
        width: 100%;
    }
    .three-buttons {
        display: inline-block;
        width: calc(100% - 150px);
    }
    .two-buttons {
        display: inline-block;
        width: calc(100% - 100px);
    }
    .one-button {
        display: inline-block;
        width: calc(100% - 50px);
    }
    .append-button {
        display: inline-block;
        width: 50px;
    }
</style>