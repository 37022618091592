<template>
    <div class="autocomplete">
        <input class="form-control"
               type="text"
               @input="onChange"
               v-model="search"
               @keyup.down="onArrowDown"
               @keyup.up="onArrowUp"
               @keyup.enter="onEnter"
               :placeholder="placeholder"
               autocomplete="off"
               :class="[state == null ? '' : state === false ? 'is-invalid' : 'is-valid']"
               :disabled="disabled"
        />
        <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
            <li class="loading" v-if="isLoading">Загрузка...</li>
            <li v-else
                v-for="(result, i) in results"
                :key="i"
                @click="setResult(result)"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }">{{ result.sourceAddressListAsStringList[0] }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Autocomplete",
        props: {
            items: {
                type: Array,
                required: false,
                default: () => []
            },
            isAsync: {
                type: Boolean,
                required: false,
                default: false
            },
            clear: {
                type: Number,
                required: false,
                default: () => 0
            },
            placeholder: {
                type: String,
                required: false,
                default: () => ''
            },
            init: null,
            disabled: {
                type: Boolean,
                required: false,
                default: () => false
            },
            state: {
                type: Boolean,
                required: false,
                default: () => null
            }
        },
        data() {
            return {
                isOpen: false,
                results: [],
                search: '',
                isLoading: false,
                arrowCounter: 0,
                wait: false
            }
        },
        methods: {
            onChange() {
                if (this.search) {
                    if (!this.wait) {
                        this.wait = true;
                        setTimeout(() => {
                            this.requestData();
                            this.wait = false;
                        }, 1000);
                    }
                } else {
                    this.results = [];
                    this.isOpen = false;
                    this.isLoading = false;
                }
            },
            requestData() {
                this.$emit('input', this.search);
                if (this.isAsync) {
                    this.isLoading = true;
                } else {
                    this.results = this.items;
                    this.isOpen = true;
                }
            },
            setResult(result) {
                this.search = result.sourceAddressListAsStringList[0];
                this.isOpen = false;
                this.$emit('select', result);
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter() {
                this.setResult(this.results[this.arrowCounter]);
                this.arrowCounter = -1;
            },
            handleClickOutside(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            }
        },
        watch: {
            items(val) {
                this.results = val;
                this.isLoading = false;
                this.isOpen = true;
            },
            clear() {
                this.search = '';
            },
            init: {
                handler(val) {
                    if (val)
                        this.search = val;
                    else
                        this.search = null;
                },
                immediate: true
            },
            search(){
                this.$emit('searchQuery', this.search);
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }
</script>

<style scoped>
    .autocomplete {
        position: relative;
    }
    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: auto;
        overflow: auto;
        width: 100%;
        position: absolute;
        z-index: 10000;
        background-color: white;
        border-top: none;
    }
    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
        z-index: 10000;
    }
    .is-active,
    .autocomplete-result:hover {
        background-color: #1227aa;
        color: white;
    }
</style>